
export default {
  data () {
    return {
      cmpinfoAct: false,
      selectOptions: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      selectValue: '',
      snsItem: [
        {
          snsLink: 'https://blog.naver.com/daisoblog',
          snsImg: '/images/icon/footer_sns_blog.svg',
          snsName: '블로그'
        },
        {
          snsLink: 'https://www.instagram.com/daisolife/',
          snsImg: '/images/icon/footer_sns_instar.svg',
          snsName: '인스타'
        },
        {
          snsLink: 'https://www.facebook.com/daisolife?fref=ts',
          snsImg: '/images/icon/footer_sns_facebook.svg',
          snsName: '페이스북'
        },
        {
          snsLink: 'https://www.youtube.com/channel/UChz7dY8O1jTD8bXGJ8JgXEA',
          snsImg: '/images/icon/footer_sns_youtube.svg',
          snsName: '유튜브'
        },
        {
          snsLink: 'https://www.tiktok.com/@daisolife/',
          snsImg: '/images/icon/footer_sns_tiktok.png',
          snsName: '틱톡'
        }
      ]
    }
  },
  methods: {
    btnEvent1 () {
      alert('FAQ')
    },
    btnEvent2 () {
      alert('제휴문의')
    },
    toggleCmpinfo () {
      this.cmpinfoAct = !this.cmpinfoAct
    }
  }
}
