
export default {
  name: 'MsTab',
  components: {
  },
  props: {
    tabType: {
      type: String,
      default: 'button'
    },
    isActive: {
      type: String,
      default: '1'
    },
    tabsData: {
      type: [Object, Array],
      default: () => {
        return {}
      }
    },
    srchTxt: { // 검색
      type: String,
      default: ''
    },
    button: { // 스와이프 button type
      type: Boolean,
      default: false
    },
    /// 23.08.24 탭 타입 추가
    anchor: { // 스와이프 anchor type
      type: Boolean,
      default: false
    },
    /// 23.08.25 탭 타입 추가
    link: { // 스와이프 link type
      type: Boolean,
      default: false
    },
    radio: { // 스와이프 radio type
      type: Boolean,
      default: false
    },
    single: { // Panel X
      type: Boolean,
      default: false
    },
    giftCard: { // 상품권 탭 type
      type: Boolean,
      default: false
    },
    typeA: { // 탭 버튼 A type
      type: Boolean,
      default: false
    },
    typeB: { // 탭 버튼 A type
      type: Boolean,
      default: false
    },
    typeC: { // 탭 버튼 C type
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ruleForm: {
        name: '' // textArea 글자
      },
      active: '1', // 탭 index(활성화)
      tabIndex: ['1'], // 아코디언 index
      swiperOpt: { // swiper options
        init: false,
        slidesPerView: 'auto',
        loop: false,
        spaceBetween: 8,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        threshold: 30,
        watchOverflow: true, // 슬라이드가 1개 일 때 pager, button 숨김 여부 설정
        centeredSlides: false, // true시에 슬라이드가 가운데로 배치
        slideToClickedSlide: false // 해당 슬라이드 클릭시 슬라이드 위치로 이동
      }
    }
  },
  computed: {
    // swiper () {
    //   return this.$refs.tabSwipe.swiper
    // }
  },
  watch: {
  },
  created () {
    if (this.isActive !== undefined) {
      this.active = this.isActive
    }

    if (this.tabType === 'tabSwiper' && this.button === true && this.typeC === true) {
      this.swiperOpt.slidesPerGroup = 4
    }
  },
  mounted () {
    if (this.$refs.tabSwipe !== undefined) {
      this.$refs.tabSwipe.swiper.init()
    }
  },
  unmounted () {
  },
  methods: {
    prodNum (e) {
      if (typeof e === 'number') {
        if (e > 99999) {
          return '99,999+'
        } else {
          return Number(e).toLocaleString('ko-KR')
        }
      } else if (Number(e.replace(/[^0-9]/g, '')) > 99999) {
        return '99,999+'
      } else {
        return Number(e.replace(/[^0-9]/g, '')).toLocaleString('ko-KR')
      }
    },
    activeOn (e) {
      const index = this.tabsData.findIndex((item) => {
        return item.id === e.id
      })
      this.active = String(index + 1)
    },
    slideWidth () { // slide 갯수에 따라 전체 넓이 체크 (필요시 호출)
      const widthSize = []
      document.querySelectorAll('.tab-wrap.tab-swiper .swiper-wrapper').forEach((item, idx) => {
        let listSize = 0
        item.classList.add('swipe-' + idx)
        document.querySelectorAll('.swipe-' + idx + ' .swiper-slide').forEach((el) => {
          listSize += el.clientWidth
        })
        widthSize.push(listSize)
        if (widthSize[idx] > 920) {
          this.$refs.tabSwipe.swiper.init()
        }
      })
    },
    slideToMove () {
      // if (this.tabType === 'tabSwiper' && this.button === true && this.typeC === true) {
      //   if (this.$refs.tabSwipe.swiper.clickedIndex !== undefined) {
      //     this.$refs.tabSwipe.swiper.slideTo(this.active - 1)
      //   }
      // }
    },
    changeCallback () {
      this.$emit('changeCallback', this.active)
    },
    changeIndex (idx) {
      this.active = idx
    }
  }
}
