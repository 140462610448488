
import Swiper from 'swiper' // 스와이퍼 재선언 시 사용
// import GnbNav from '@/components/pub/GnbNav.vue'
import ButtonEl from '@/components/pub/ButtonEl.vue'
import TabCmp from '@/components/pub/TabCmp.vue' // 탭

export default {
  name: 'HeaderCmp',
  components: {
    // GnbNav,
    ButtonEl,
    TabCmp
  },
  props: {
    headerType: {
      type: String,
      default: ''
    },
    pageName: {
      type: String,
      default: ''
    },
    tabActive: {
      type: String,
      default: 'tab01'
    },
    backBtn: {
      type: Boolean,
      default: false
    },
    searchBtn: {
      type: Boolean,
      default: false
    },
    searchArea: {
      type: Boolean,
      default: false
    },
    basketBtn: {
      type: Boolean,
      default: false
    },
    qrBtn: {
      type: Boolean,
      default: false
    },
    mapBtn: {
      type: Boolean,
      default: false
    },
    closeBtn: {
      type: Boolean,
      default: false
    },
    moreBtn: {
      type: Boolean,
      default: false
    },
    enroBtn: {
      type: Boolean,
      default: false
    },
    homeBtn: {
      type: Boolean,
      default: false
    },
    setBtn: {
      type: Boolean,
      default: false
    },
    alarmBtn: {
      type: Boolean,
      default: false
    },
    stepLine: {
      type: Boolean,
      default: false
    },
    perVal: {
      type: String,
      default: ''
    },
    headerWhite: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeTab: 'tab1', // 검색 순위 팝업 내부 탭
      swiperOpt: { // GNB 스와이퍼
        slidesPerView: 'auto',
        loop: false,
        FreeMode: true
      },
      gnbActive: 3, // 23.10.12 gnb active idx
      gnbList: [
        { link: '#', name: '추천', img: '' },
        { link: '#', name: '신상', img: '', new: true },
        { link: '#', name: '이번주', img: '' },
        { link: '#', name: '정기배송', img: '' },
        { link: '#', name: '랭킹', img: '' },
        { link: '#', name: '이벤트', img: '/images/tempLy/gnb_event.png' }
      ],
      input1: '',
      bubbleShow: true,
      visibleAd: false, // 배송지 설정 팝업
      visibleRank: false, // 인기 검색어 팝업
      visibleRankTool: false, // 인기 검색어 기준 툴팁
      active: '1', // 탭 index(활성화)
      tabsDataSub: [ // 탭 data
        {
          id: '1',
          title: '1~10위',
          name: '1'
        },
        {
          id: '2',
          title: '11~20위',
          name: '2'
        }
      ],
      searchRankList: [ // 검색 순위 리스트
        [
          { rank: 1, name: '보조배터리', up: 3, down: '' },
          { rank: 2, name: '세탁세제', up: '', down: '' },
          { rank: 3, name: '디즈니공주', up: '', down: 1 },
          { rank: 4, name: '캠핑용품', up: '', down: 1 },
          { rank: 5, name: '선풍기', up: '', down: 1 },
          { rank: 6, name: '자외선차단제', up: '', down: '', new: true },
          { rank: 7, name: '쿨링매트', up: '', down: '' },
          { rank: 8, name: '보조배터리', up: '', down: 1 },
          { rank: 9, name: '보조배터리보조배터리', up: '', down: 1 },
          { rank: 10, name: '보조배터리', up: '', down: 1 }
        ],
        [
          { rank: 11, name: '보조배터리', up: 3, down: '' },
          { rank: 12, name: '세탁세제', up: '', down: 1 },
          { rank: 13, name: '디즈니공주', up: '', down: '' },
          { rank: 14, name: '캠핑용품', up: '', down: 1 },
          { rank: 15, name: '선풍기', up: '', down: '' },
          { rank: 16, name: '자외선차단제', up: '', down: 1 },
          { rank: 17, name: '쿨링매트', up: '', down: 1 },
          { rank: 18, name: '보조배터리', up: '', down: 1 },
          { rank: 19, name: '보조배터리보조배터리', up: '', down: 1 },
          { rank: 20, name: '보조배터리', up: '', down: 1 }
        ]
      ],
      rankingSwiperOpt: { // 검색 순위 스와이퍼
        slidesPerView: 1,
        loop: true,
        direction: 'vertical',
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
          autoplayDisableOnInteraction: false
        }
      },
      popRankSwiperOpt: {
        auto: false,
        loop: false,
        navigation: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets', // 버튼 디자인 bullets" / "fraction"
          renderBullet (index, className) {
            return '<button type="button" aria-label="' + (index + 1) + '번 슬라이드로 이동하기" class="' + className + '"><span class="blind">' + (index + 1) + '</span></button>'
          },
          renderFraction (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' + '<span>/</span>' + '<span class="' + totalClass + '"></span>'
          }
        },
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      searchInput: '', // 검색 input
      searchPopRecent: false, // 최근 검색어 팝업
      searchPopAuto: false, // 검색 자동완성 팝업
      swiperTotalWidth: 0, // 스와이퍼 총 넓이
      swipeWrapWidth: 0, // 스와이퍼 제어 대상 부모 넓이
      isDestroy: false, // 스와이퍼 작동 중지 여부

      // 23.08.25
      fullCategorys: [ // 카테고리 리스트
        {
          id: 1,
          menu: '공구/디지털',
          link: '#',
          icon: '/images/icon/icon_category_01.svg',
          activeIcon: '/images/icon/icon_category_01_active.svg',
          iconAlt: '',
          subMenu: [
            {
              subTitle: '건전지/콘센트',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: true // 23.10.12 default 수정
        },
        {
          id: 2,
          menu: '문구/팬시',
          link: '#',
          icon: '/images/icon/icon_category_02.svg',
          activeIcon: '/images/icon/icon_category_02_active.svg',
          iconAlt: '',
          submenuTitle: '문구/팬시',
          subMenu: [
            {
              subTitle: '문구/팬시',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 3,
          menu: '반려동물',
          link: '#',
          icon: '/images/icon/icon_category_03.svg',
          activeIcon: '/images/icon/icon_category_03_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 4,
          menu: '뷰티/위생',
          link: '#',
          icon: '/images/icon/icon_category_04.svg',
          activeIcon: '/images/icon/icon_category_04_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 5,
          menu: '수납',
          link: '#',
          icon: '/images/icon/icon_category_05.svg',
          activeIcon: '/images/icon/icon_category_05_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 6,
          menu: '스포츠/레저/취미',
          link: '#',
          icon: '/images/icon/icon_category_06.svg',
          activeIcon: '/images/icon/icon_category_06_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 7,
          menu: '식품',
          link: '#',
          icon: '/images/icon/icon_category_07.svg',
          activeIcon: '/images/icon/icon_category_07_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 8,
          menu: '유아/완구',
          link: '#',
          icon: '/images/icon/icon_category_08.svg',
          activeIcon: '/images/icon/icon_category_08_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 9,
          menu: '인테리어',
          link: '#',
          icon: '/images/icon/icon_category_09.svg',
          activeIcon: '/images/icon/icon_category_09_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 10,
          menu: '주방용품',
          link: '#',
          icon: '/images/icon/icon_category_10.svg',
          activeIcon: '/images/icon/icon_category_10_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 11,
          menu: '청소/욕실',
          link: '#',
          icon: '/images/icon/icon_category_11.svg',
          activeIcon: '/images/icon/icon_category_11_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        },
        {
          id: 12,
          menu: '패션/잡화',
          link: '#',
          icon: '/images/icon/icon_category_12.svg',
          activeIcon: '/images/icon/icon_category_12_active.svg',
          iconAlt: '',
          submenuTitle: '이어폰',
          subMenu: [
            {
              subTitle: '반려동물',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            },
            {
              subTitle: '소형가전',
              subMenuLink: '#',
              subList: [
                {
                  link: '#',
                  name: 'AA 건전지'
                },
                {
                  link: '#',
                  name: 'AAA 건전지'
                },
                {
                  link: '#',
                  name: '기타 건전지'
                },
                {
                  link: '#',
                  name: '멀티탭'
                }
              ]
            }
          ],
          isActive: false
        }
      ],
      fullCateVisible: false, // 카테고리 목록 mouseover 여부
      onFullCate: false, // 카테고리 목록 active
      activeIndex: 0, // 서브카테고리 액티브 // 23.10.12 default 수정

      // 23.12.05 랭킹리스트 변경 추가
      currentIndex: 0,
      itemHeight: 20,
    }
  },
  computed: {
    swiper () {
      return this.$refs.reactiveSwiper.swiper
    },
    // 23.12.05 랭킹리스트 변경 추가
    rankingList () {
      return this.searchRankList[0].concat(this.searchRankList[1])
    },
  },
  mounted () {
    this.swiperWidth()
    this.swiperDestroy('gnb-wrap') // 파라미터 : 스와이퍼 제어 대상의 부모
    // window.addEventListener('resize', () => {
    //   this.swiperDestroy('gnb-wrap')
    // })
    window.addEventListener('click', (e) => { // search input 팝업 제어
      this.searchPopup(e, 'blur')
    })

    // 23.10.17 추가
    this.showAdPopover()

    // 23.12.05 랭킹리스트 변경 추가
    this.startRolling()
  },
  beforeDestroy () {
    // window.removeEventListener('click')
  },
  methods: {
    topsearch () {
      alert('검색')
    },
    shippingAd () {
      alert('배송지설정')
    },
    recentPd () {
      alert('최근 본 상품')
    },
    cart () {
      alert('장바구니')
    },
    goBack () {
      alert('go back')
    },
    handleFocus () {
      this.searchPopup()
    },
    handleChange () {
      this.searchPopup()
    },
    searchPopup (e, type) { // 검색 팝업 제어
      switch (type) {
        case 'blur':
          // 검색영역 제외한 부분 클릭 시
          if (e.target.closest('.search-wrap') === null) {
            this.searchPopRecent = false
            this.searchPopAuto = false
            // backTab 인풋 영역 focusout
          } else if (e.relatedTarget !== null && e.relatedTarget.classList.contains('logo')) {
            this.searchPopAuto = false
            this.searchPopRecent = false
          }
          break
        case 'popBlur':
          // 팝업 내 마지막 요소 focusout
          if (e.target.classList.contains('focus-last')) {
            this.searchPopAuto = false
            this.searchPopRecent = false
          }
          break
        default:
          // 최근 검색어, 자동완성 팝업 상태 제어 (예시)
          if (this.searchInput.length === 0) {
            this.searchPopRecent = true
            this.searchPopAuto = false
          } else {
            this.searchPopAuto = true
            this.searchPopRecent = false
          }
          break
      }
    },

    // == gnb swipe //
    swiperWidth () { // 슬라이드 총 넓이
      const swiperItem = this.$refs.reactiveSwiper.$el.querySelectorAll('.swiper-slide')
      let sum = 0
      swiperItem.forEach((item) => {
        sum += item.clientWidth
      })
      this.swiperTotalWidth = sum
    },
    swiperDestroy (swipeWrap) { // 슬라이드 제어
      this.swipeWrapWidth = document.getElementsByClassName(swipeWrap)[0].clientWidth

      if (this.swiperTotalWidth < this.swipeWrapWidth && this.isDestroy === false) {
        this.$refs.reactiveSwiper.swiper.destroy()
        this.isDestroy = true
        console.log('destroy ' + this.isDestroy)
      } else if (this.swiperTotalWidth > this.swipeWrapWidth && this.isDestroy === true) {
        this.$refs.reactiveSwiper.swiper = new Swiper(this.$refs.reactiveSwiper.$el, this.swiperOpt)
        this.isDestroy = false
        console.log('destroy ' + this.isDestroy)
      }
    },
    // bubbleSet () {
    //   setTimeout(() => {
    //     this.bubbleShow = false
    //   }, 2000)
    // }

    //
    // 23.08.25 카테고리 함수 추가
    activateMenuItem (index) {
      this.activeIndex = index
      // 23.10.12 추가
      if (this.fullCategorys[0].isActive === true) {
        this.fullCategorys[0].isActive = false
      }
      this.fullCategorys[index].isActive = true
    },
    deactivateMenuItem (index) {
      this.activeIndex = -1
      // 23.10.12 추가
      if (this.fullCategorys[0].isActive === true) {
        this.fullCategorys[0].isActive = false
      }
      this.fullCategorys[index].isActive = false
    },
    isActive (index) {
      return this.activeIndex === index
    },
    cateActive (e) {
      if (e.target.closest('.full-category') !== null) {
        this.onFullCate = true
      } else {
        this.onFullCate = false
      }
      this.fullCateVisible = true
    },
    cateClose (e) {
      if (this.onFullCate === false) {
        this.fullCateVisible = false
      } else if (this.onFullCate && e.target.closest('.full-category') !== null) {
        this.fullCateVisible = false
      }
      // 23.10.12 추가
      this.activeIndex = 0
      this.fullCategorys[0].isActive = true
    },

    // 23.10.17 추가
    showAdPopover () {
      this.visibleAd = true
      setTimeout(() => {
        this.visibleAd = false
      }, 5000)
    },

    // 23.12.05 랭킹리스트 변경 추가
    calcTranslateY (index) {
      const translateY = index * this.itemHeight
      return translateY
    },

    startRolling () {
      const slideTarget = this.$refs.rankingList
      const firstSilde = slideTarget.children[0].cloneNode(true)

      slideTarget.appendChild(firstSilde)

      setInterval(() => {
        if (this.currentIndex < this.rankingList.length) {
          this.currentIndex += 1
        } else if (this.currentIndex === this.rankingList.length) {
          slideTarget.classList.add('transition-none')
          this.currentIndex = 0
          setTimeout(() => {
            slideTarget.classList.remove('transition-none')
          })
        }
      }, 2000)
    },

  }
}
