
export default {
  props: {
    tags: {
      type: String,
      default: 'button'
    },
    iconname: {
      type: String,
      default: ''
    },
    disabled: {
      type: String,
      default: ''
    },
    icontext: {
      type: String,
      default: ''
    },
    iconpos: {
      type: String,
      default: ''
    },
    badgeData: {
      type: String,
      default: ''
    },
    img: {
      type: Boolean,
      default: false
    },
    iclick: {
      type: Function,
      default () {
        return 'Default function'
      }
    }
  }
}
