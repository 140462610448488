
export default {
  data () {
    return {
      topbaner: true
    }
  },
  methods: {
    closetopBn () {
      console.log('탑배너 닫기')
      this.topbaner = !this.topbaner
    }
  }
}
